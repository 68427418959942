import { Home } from 'views/website/home';
import { Contact } from 'views/website/contact';
import { Pricing } from 'views/website/pricing';
import { Terms } from 'views/website/terms';
import { Privacy } from 'views/website/privacy';
import { About } from 'views/website/about';

import { Freewait } from 'views/website/freewait';
import { Freerun } from 'views/website/freerun';

const Routes = [
  {
    path: '/',
    view: Home,
    layout: 'home',
    title: 'CheckOps Monkey| Monkey Test your website '
  },
  {
    path: '/contact',
    view: Contact,
    layout: 'home',
    title: 'Contact'
  },
  {
    path: '/pricing',
    view: Pricing,
    layout: 'home',
    title: 'Pricing'
  },
  {
    path: '/privacy',
    view: Privacy,
    layout: 'home',
    title: 'Privacy Policy'
  },
  {
    path: '/freerun/:id',
    view: Freerun,
    layout: 'app',
    title: 'Freerun'
  },
  {
    path: '/freewait/:id',
    view: Freewait,
    layout: 'home',
    title: 'Freewait'
  },
  {
    path: '/terms',
    view: Terms,
    layout: 'home',
    title: 'Terms'
  },
  {
    path: '/about',
    view: About,
    layout: 'home',
    title: 'About'
  },
]

export default Routes;


