const Style = {
  feature: 'relative bg-white border rounded-lg shadow-lg p-6 text-center',
  icon: 'block w-16 h-16 mx-auto mb-4',
  title: 'text-blue-500 font-semibold uppercase mt-4',
  description: 'mt-2',
  link: 'inline-block mt-4 text-red-500 font-bold no-underline hover:underline',
  status: 'absolute top-0 left-0 px-2 py-1 rounded-br-lg text-sm cursor-pointer',
  upcoming: 'bg-orange-500 text-white',
  live: 'bg-green-500 text-white',
  updated: 'bg-yellow-500 text-black',
  planned: 'bg-blue-500 text-white',
};

export default Style;
