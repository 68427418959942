/**********
*
*   ABOUT CHECKOPS MONKEY
*   Crisp and easy to understand for VCs and B2B
*
**********/

import React from 'react';

export function About(props){

  return(

    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: '0 auto' }}>

      <div style={{ border: '1px  #ccc', padding: '20px', borderRadius: '10px', marginBottom: '20px' }}>
        <h1 style={{ fontSize: '2.5em', marginBottom: '20px' }}>Problem: Website Quality</h1>
        
        <p style={{ fontSize: '1.2em', marginBottom: '20px' }}>
          Keeping your website problem-free is tough. When you launch a new version, you often don't know if something is broken unless you check every part yourself. Issues can be small, like missing images,configs , broken links or big like heavy pages, network or click errors on your page. 
        </p>
        <p style={{ fontSize: '1.2em', marginBottom: '20px' }}>
          Experts usually suggest writing your own tests or hiring manual testers to routinely check your site. However, creating and maintaining tests can be time-consuming, and manual testers can be costly and need oversight. Ideally, you would have both if you have unlimited time and money but until that happens, what's your alternative?
        </p>
      </div>

      <div style={{ border: '1px  #ccc', padding: '20px', borderRadius: '10px', marginBottom: '20px' }}>
        <h1 style={{ fontSize: '2.5em', marginTop: '40px', marginBottom: '20px' }}>Solution: CheckOps Monkeys.</h1>
        
        <p style={{ fontSize: '1.2em', marginBottom: '20px' }}>
          CheckOps Monkey provides an effortless approach to automated website testing, allowing you to concentrate on improving your site's design and functionality without worrying about learning complex testing tools. You don't need to code anything; just provide your URL, and we take care of everything else. We've identified the most frequent issues websites encounter and developed specialized software to detect these specific problems.
        </p>
      </div>

      <div style={{ border: '1px  #ccc', padding: '20px', borderRadius: '10px', marginBottom: '20px' }}>
        <h1 style={{ fontSize: '2.5em', marginTop: '40px', marginBottom: '20px' }}>Why us?</h1>
        
        <p style={{ fontSize: '1.2em', marginBottom: '20px' }}>
          Our website checkers are powered by a unique breed of algorithmically enhanced primates that thrive on finding website issues and scoring your site. Let’s meet them.
        </p>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        
        <div className="box">
          <h2 style={{ fontSize: '1.5em', marginBottom: '20px' }}>Auditor Monkey</h2>
          <img src="https://pic.funnygifsbox.com/uploads/2018/10/funnygifsbox.com-2018-10-19-10-34-08-45.gif" alt="Auditor Monkey" style={{ width: '100px', height: '100px', marginBottom: '20px' }} />
          <p style={{ fontSize: '1.2em', marginBottom: '20px' }}>
            Auditor Monkey audits your website against top best practices and scores your page. It ensures that your site adheres to the latest standards.
          </p>
        </div>

        <div className="box">
          <h2 style={{ fontSize: '1.5em', marginBottom: '20px' }}>Detective Monkey</h2>
          <img src="https://pic.funnygifsbox.com/uploads/2018/10/funnygifsbox.com-2018-10-19-10-33-26-37.gif" alt="Detective Monkey" style={{ width: '100px', height: '100px', marginBottom: '20px' }} />
          <p style={{ fontSize: '1.2em', marginBottom: '20px' }}>
            Detective Monkey explores the page to detect broken links and list network and JavaScript errors. It digs deep into your site to find hidden issues.
          </p>
        </div>

        <div className="box">
          <h2 style={{ fontSize: '1.5em', marginBottom: '20px' }}>Boxer Monkey</h2>
          <img src="https://pic.funnygifsbox.com/uploads/2018/10/funnygifsbox.com-2018-10-19-10-34-07-78.gif" alt="Boxer Monkey" style={{ width: '100px', height: '100px', marginBottom: '20px' }} />
          <p style={{ fontSize: '1.2em', marginBottom: '20px' }}>
            Boxer Monkey clicks every element on your page and looks for any errors. It ensures that all interactive elements on your site are error-free.
          </p>
        </div>

        <div className="box">
          <h2 style={{ fontSize: '1.5em', marginBottom: '20px' }}>X Monkey?</h2>
          <img src="https://pic.funnygifsbox.com/uploads/2018/10/funnygifsbox.com-2018-10-19-10-34-45-34.gif" alt="Boxer Monkey" style={{ width: '100px', height: '100px', marginBottom: '20px' }} />
          <p style={{ fontSize: '1.2em', marginBottom: '20px' }}>
            New monkeys will be being created based on users demands. Subscribe on our main site for updates and contact us for new user access!
          </p>
        </div>

      </div>

      <style jsx>{`
        .box {
          flex: 1 1 calc(50% - 10px);
          max-width: calc(50% - 10px);
          padding: 20px;
          text-align: -webkit-center;
          border: 1px solid #ccc;
          border-radius: 10px;
          margin-bottom: 20px;
        }
        
        @media (max-width: 768px) {
          .box {
            flex: 1 1 100%;
            max-width: 100%;
            margin-bottom: 20px;
          }
        }
      `}</style>

    </div>
  )
}
