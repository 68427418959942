import { Fragment, useState, useContext  } from 'react';
//import { useNavigate } from 'react-router-dom';
import { AuthContext, ViewContext, Form, Card,
  AccountNav, Button, Loader,useNavigate, Animate, useAPI, Event } from 'components/lib';
  import settings from 'settings';
  
  import '../css/run.css';

export function Run(props){

  const navigate = useNavigate();

  const [url, setUrl] = useState('');
  const [screenshoturl, setscreenshotUrl] = useState('');
  const [globalscore, setglobalScore] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [status, setStatus] = useState('');
  const [result, setResult] = useState([]);
  const [scores, setScores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [rules, setRules] = useState([]);
  
  const data = useAPI('/api/run');
  // fetch
  const user = useAPI('/api/user');
  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const base = settings[process.env.NODE_ENV].server_url;
  

  const handleInputChange = (e) => {
    setUrl(e.target.value);
  };

  const handleChoice = (option) => {
    console.log('Selected option:', option);
  };

  function generateID(url) {
    // Get the current timestamp in UTC
    const timestamp = Date.now();
  
    // Extract the first 5 letters of the URL
    const urlPrefix = url.slice(0, 5);
  
    // Generate the alphanumeric ID by combining timestamp and URL prefix
    const id = `p${timestamp.toString(36)}`;
  
    return id;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);  

    try {

      const requestId = generateID(url);
      // Call POST API 1
      const api1Response = await callApi1(requestId,url);
      // Redirect to the queue page with the provided ID
      navigate(`/queue/${requestId}`);

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const callApi1 = async (id,url) => {
    // Call POST API 1 here and return the response
    // Example: const response = await fetch('/api1', { method: 'POST', body: { url } });
    // Replace '/api1' with your API endpoint
    // Return the response data
    
    const settings = {
        method: 'POST',
        headers: {
            'accept': 'application/json, text/plain, */*',
            'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
              'id': `${id}`,
               'url': `${url}`,
               }),
    };
    try {
        const fetchResponse = await fetch(`${base}/api/run/`, settings);
        const data = await fetchResponse.json();
        return data;
    } catch (e) {
        return e;
    }    
    //return { requestId: 'runId' };
  };




  if (!data)
    return false;

  return(
 
    <div className="container">
      <div className="input-container">
        <input
          type="text"
          className="input-field"
          placeholder="https://www.site.com"
          value={url}
          onChange={handleInputChange}
        />
        <div className="button-container">
          <button className="button" onClick={handleSubmit}>
            Check 
          </button>
        </div>
      </div>
      </div> 
  
  );
}

/*
      <div className="button-container">
        <button className="button" onClick={() => handleChoice('mobile')}>
          Mobile
        </button>
        <button className="button" onClick={() => handleChoice('desktop')}>
          Desktop
        </button>
      </div>
*/             
