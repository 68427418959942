import React from 'react';
import { ClassHelper } from 'components/lib';
import Style from './features.tailwind.js';

export function Features(props) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      <Feature
        iconUrl="https://pic.funnygifsbox.com/uploads/2018/10/funnygifsbox.com-2018-10-19-10-34-08-45.gif"
        title='Auditor Monkey'
        desc='Audits your website against top best practices and scores your page.'
        link='/about'
        linkText='Learn More'
        status="live"
        tooltip="This feature is currently live."
      />
      <Feature
        iconUrl="https://pic.funnygifsbox.com/uploads/2018/10/funnygifsbox.com-2018-10-19-10-33-26-37.gif"
        title='Detective Monkey'
        desc='Explores the page to detect broken links and list network and JS errors.'
        link='/about'
        linkText='Learn More'
        status="upcoming"
        tooltip="This feature is upcoming."
      />
      <Feature
        iconUrl="https://pic.funnygifsbox.com/uploads/2018/10/funnygifsbox.com-2018-10-19-10-34-07-78.gif"
        title='Boxer Monkey'
        desc='Clicks every element on your page and looks for any errors popping up.'
        link='/about'
        linkText='Learn More'
        status="planned"
        tooltip="This feature is planned."
      />
    </div>
  );
}

function Feature(props) {
  const featureStyle = ClassHelper(Style, {
    feature: true,
    className: props.className,
  });

  return (
    <div className={featureStyle}>
      {props.status && (
        <div className={`${Style.status} ${Style[props.status]}`} title={props.tooltip}>
          {props.status.charAt(0).toUpperCase() + props.status.slice(1)}
        </div>
      )}
      <div className={Style.icon}>
        <img src={props.iconUrl} alt={`${props.title} icon`} />
      </div>
      <h3 className={Style.title}>{props.title}</h3>
      <p className={Style.description}>{props.desc}</p>
      <a href={props.link} className={Style.link}>{props.linkText} &rarr;</a>
    </div>
  );
}
