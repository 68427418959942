const Style = {

  wrapper: 'h-24',
  nav: 'absolute top-1/2 -translate-y-1/2 right-0',
  logo: 'absolute top-1/2 -translate-y-1/2 left-0 m0',
  transparent: 'bg-none bg-transparent',
  color: 'black',
  link: 'mr-5 last:mr-0 no-underline text-sm',
  button: 'inline-block px-3 py-2',

}

export default Style;