/***
*
*   HERO
*   Hero section on landing page
*
*   PROPS
*   title: h1 title
*   tagline: h2 tagline
*   image: source object
*   alt: img alt tag
*
**********/

import { Animate, Content, Button, Image, ClassHelper, AuthContext, ViewContext, Form, Card, AccountNav, Loader, useAPI, Event } from 'components/lib';
import Style from './hero.tailwind.js';

import { Fragment, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../css/runfree.css';

export function Hero(props){

  const heroStyle = ClassHelper(Style, {
    hero: true,
    className: props.className
  });

  const navigate = useNavigate();

  const [url, setUrl] = useState('');
  const [screenshoturl, setscreenshotUrl] = useState('');
  const [globalscore, setglobalScore] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [status, setStatus] = useState('');
  const [result, setResult] = useState([]);
  const [scores, setScores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [rules, setRules] = useState([]);

const base = 'https://beta.checkops.com';

const handleInputChange = (e) => {
  setUrl(e.target.value);
};

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);

  try {
    // Call POST API 1
    const api1Response = await callApi1(url);
    const requestId = api1Response.runId;
    // Redirect to the queue page with the provided ID
    navigate(`/freewait/${requestId}`);

  } catch (error) {
    console.error('Error:', error);
  } finally {
    setIsLoading(false);
  }
};

const callApi1 = async (url) => {
  // Call POST API 1 here and return the response
  // Example: const response = await fetch('/api1', { method: 'POST', body: { url } });
  // Replace '/api1' with your API endpoint
  // Return the response data

  const settings = {
      method: 'POST',
      headers: {
          'Accept': 'application/json, text/plain, */*',
          'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
             'url': `${url}`,
             'waitForResponse': 'false',
             'screenshot': 'true',
             'device': 'desktop',
             }),
  };
  try {
      const fetchResponse = await fetch(`${base}/api/runs/`, settings);
      const data = await fetchResponse.json();
      return data;
  } catch (e) {
      return e;
  }
  //return { requestId: 'runId' };
};

  return (
    <section className={ heroStyle }>
      <Content>

        <Animate type='pop'>
          <section className={ Style.blurb }>

            <h1 className={ Style.title }>{ props.title }</h1>
            <h2 className={ Style.tagline }>{ props.tagline }</h2>
            <div className="container">
              <div className={`input-container ${Style.searchBar}`}>
                <input
                  type="text"
                  className={`input-field ${Style.searchText}`}
                  placeholder="https://www.site.com"
                  value={url}
                  onChange={handleInputChange}
                />
                <div className={`button-container ${Style.button}`}>
                  <button className={`button ${Style.button}`} onClick={handleSubmit}>
                    TRY NOW
                  </button>
                </div>
              </div>
            </div>

          </section>
        </Animate>

        <Animate>
          <Image className={ Style.image } source={ props.image } alt={ props.alt } />
        </Animate>

      </Content>
    </section>
  )
}
