import { Monitoring } from 'views/monitoring';
import { Schedule } from 'views/schedule';
import { Freewait } from 'views/website/freewait';
import { Freerun } from 'views/website/freerun';
import { Waiting } from 'views/waiting';
import { Result } from 'views/result';
import { Queue } from 'views/queue';
import { Run } from 'views/run';
import { Dashboard } from 'views/dashboard/dashboard';
import { Help } from 'views/dashboard/help';
import { OnboardingView } from 'views/onboarding/onboarding';

const Routes = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help'
  },
  {
    path: '/run',
    view: Run,
    layout: 'app',
    permission: 'user',
    title: 'Run Test'
  },
  {
    path: '/queue/:id',
    view: Queue,
    layout: 'app',
    permission: 'user',
    title: 'Queue'
  },
  {
    path: '/result/:id',
    view: Result,
    layout: 'app',
    permission: 'user',
    title: 'Result'
  },
  {
    path: '/waiting',
    view: Waiting,
    layout: 'app',
    permission: 'user',
    title: 'Waiting'
  },
  {
    path: '/schedule',
    view: Schedule,
    layout: 'app',
    permission: 'user',
    title: 'Schedule'
  },
  {
    path: '/monitoring',
    view: Monitoring,
    layout: 'app',
    permission: 'user',
    title: 'Monitoring'
  },
]

export default Routes;
